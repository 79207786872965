import useSWR from "swr";
import {NotificationsRepository} from "@src/repositories/NotificationsRepository";
import {useParams} from "react-router";
import {ORDERS_REFRESH_INTERVAL} from "@src/config";
import {BaseRouteParams} from "@routes/api_routes";

export const useCompanyNotifications = (load= true) => {
    const {accountId, companyId, companyAddressId,} = useParams<BaseRouteParams>()
    return useSWR(companyId && load && `/no-cache/${companyId}/notifications`, () => NotificationsRepository.getAll({
        accountId: accountId as string,
        companyId: companyId as string,
        companyAddressId: companyAddressId
    }), {
        refreshInterval: ORDERS_REFRESH_INTERVAL,
        dedupingInterval: ORDERS_REFRESH_INTERVAL
    })
}

export const useHasNewNotifications = () => {
    const {accountId, companyId, companyAddressId,} = useParams<BaseRouteParams>()

    return useSWR(`/no-cache/notifications/has-new`, () => NotificationsRepository.hasNew({
        accountId: accountId as string,
        companyId: companyId as string,
        companyAddressId: companyAddressId
    }), {
        refreshInterval: ORDERS_REFRESH_INTERVAL,
        dedupingInterval: ORDERS_REFRESH_INTERVAL
    })
}