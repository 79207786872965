import {useEffect, useMemo} from "react";
import {StorageManager} from '@features/storage-manager/storage-manager'
import {createStore, useStore} from "zustand";
import {useParams} from "react-router";
import {isAllSelected} from "@shared/lib/helpers";
import {Permissions} from "@dto/AuthResponseDTO";

type IAuthStore = {
    userId: string,
    setUserId: (userId: string) => void
    setLevel: (level: number) => void
    setPermissions: (permissions: Permissions[]) => void
    level: number,
    permissions: Permissions[]
}
const authStore = createStore<IAuthStore>((set) => ({
    userId: '',
    level: 0,
    permissions: [],
    setUserId: (userId: string) => set({userId}),
    setLevel: (level: number) => set({level}),
    setPermissions: (permissions: Permissions[]) => set({permissions})
}))

export const useAuth = () => {
    const store = useStore(authStore)
    const {companyId} = useParams()
    useEffect(() => {
        mount()
    }, [])

    const mount = () => {
        const uid = StorageManager.getFromLocal(StorageManager.KEYS.USER_ID, '')
        const level = StorageManager.getFromLocal(StorageManager.KEYS.USER_LEVEL, 0);
        const permissions = StorageManager.getFromLocal(StorageManager.KEYS.USER_PERMISSIONS, []);
        store.setLevel(level)
        store.setPermissions(permissions)
        store.setUserId(uid)
    }

    const permissions = useMemo(() => {
        const isAdmin = store.level === 3
        const check = (...permission: Permissions[]) => {
            if (isAdmin) return true
            if (permission.length === 0) return false
            if (permission.length === 1) return store.permissions.includes(permission[0])
            return permission.every(p => store.permissions.includes(p))
        }

        return  {
            isAdmin,
            view: {
                cashback: check(Permissions.CASHBACK_EDIT)
            },
            canSelectAllCompanies: check(Permissions.COMPANIES_INDEX),
        }
    }, [store.permissions, store.level])

    return {
        userId: store.userId,
        level: store.level,
        isAuthorized: !!store.userId,
        isAllCompaniesSelected: isAllSelected(companyId),
        permissions,
        mount,

    }
}