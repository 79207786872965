import {COMPANY_STATUS} from "@dto/CompanyDTO";

export interface AuthResponseDTO {
    authToken: string
    user: AuthUserDTO
}

export interface AuthUserDTO {
    id: string
    accountId: string
    phone?: string
    email?: string
    level: number
    permissions: Record<string, number> | null
    companies: AuthCompaniesDTO []
}

export interface AuthCompaniesDTO {
    id: string
    name: string
    cityName: string
    status: COMPANY_STATUS
    addresses: { id: string, address: string }[]
}

export enum Permissions {
    AB_TESTS_INDEX = 'abTest.index',
    AB_TESTS_CREATE = 'abTest.create',
    AB_TESTS_EDIT = 'abTest.edit',
    AB_TESTS_DESTROY = 'abTest.destroy',
    AB_TESTS_HISTORY = 'abTest.history',

    ACCOUNTS_INDEX = 'account.index',
    ACCOUNTS_CREATE = 'account.create',
    ACCOUNTS_EDIT = 'account.edit',
    ACCOUNTS_DESTROY = 'account.destroy',
    ACCOUNTS_HISTORY = 'account.history',

    SUBSCRIPTIONS_PLAN_INDEX = 'subscription-plan.index',
    SUBSCRIPTIONS_PLAN_CREATE = 'subscription-plan.create',
    SUBSCRIPTIONS_PLAN_EDIT = 'subscription-plan.edit',
    SUBSCRIPTIONS_PLAN_DESTROY = 'subscription-plan.destroy',
    SUBSCRIPTIONS_PLAN_HISTORY = 'subscription-plan.history',

    FEATURES_INDEX = 'feature.index',
    FEATURES_CREATE = 'feature.create',
    FEATURES_EDIT = 'feature.edit',
    FEATURES_DESTROY = 'feature.destroy',
    FEATURES_HISTORY = 'feature.history',

    FEATURE_GROUPS_INDEX = 'feature-group.index',
    FEATURE_GROUPS_CREATE = 'feature-group.create',
    FEATURE_GROUPS_EDIT = 'feature-group.edit',
    FEATURE_GROUPS_DESTROY = 'feature-group.destroy',
    FEATURE_GROUPS_HISTORY = 'feature-group.history',

    ACCOUNT_LANGS_INDEX = 'account-lang.index',
    ACCOUNT_LANGS_CREATE = 'account-lang.create',
    ACCOUNT_LANGS_EDIT = 'account-lang.edit',
    ACCOUNT_LANGS_DESTROY = 'account-lang.destroy',
    ACCOUNT_LANGS_HISTORY = 'account-lang.history',

    PROMOTIONS_INDEX = 'action.index',
    PROMOTIONS_CREATE = 'action.create',
    PROMOTIONS_EDIT = 'action.edit',
    PROMOTIONS_DESTROY = 'action.destroy',
    PROMOTIONS_HISTORY = 'action.history',

    APP_TOKENS_INDEX = 'app-token.index',
    APP_TOKENS_CREATE = 'app-token.create',
    APP_TOKENS_EDIT = 'app-token.edit',
    APP_TOKENS_DESTROY = 'app-token.destroy',
    APP_TOKENS_HISTORY = 'app-token.history',

    APPEARANCES_INDEX = 'appearance.index',
    APPEARANCES_CREATE = 'appearance.create',
    APPEARANCES_EDIT = 'appearance.edit',
    APPEARANCES_DESTROY = 'appearance.destroy',
    APPEARANCES_HISTORY = 'appearance.history',

    CITIES_INDEX = 'city.index',
    CITIES_CREATE = 'city.create',
    CITIES_EDIT = 'city.edit',
    CITIES_DESTROY = 'cities.destroy',
    CITIES_HISTORY = 'city.history',
    CITIES_REPORTS = 'cities.reports',

    CITY_CATEGORIES_INDEX = 'category.index',
    CITY_CATEGORIES_CREATE = 'category.create',
    CITY_CATEGORIES_EDIT = 'category.edit',
    CITY_CATEGORIES_DESTROY = 'category.destroy',
    CITY_CATEGORIES_HISTORY = 'category.history',

    CLASSIFIERS_INDEX = 'classifier.index',
    CLASSIFIERS_CREATE = 'classifier.create',
    CLASSIFIERS_EDIT = 'classifier.edit',
    CLASSIFIERS_DESTROY = 'classifier.destroy',
    CLASSIFIERS_HISTORY = 'classifier.history',

    COMPANIES_INDEX = 'company.index',
    COMPANIES_CREATE = 'company.create',
    COMPANIES_COPY = 'company.copy',
    COMPANIES_CATALOG_COPY = 'company.catalog.copy',
    COMPANIES_EDIT = 'company.edit',
    COMPANIES_DESTROY = 'company.destroy',
    COMPANIES_HISTORY = 'company.history',

    COMPANY_ADDRESSES_INDEX = 'company-address.index',
    COMPANY_ADDRESSES_CREATE = 'company-address.create',
    COMPANY_ADDRESSES_EDIT = 'company-address.company-address-edit', // @todo fix naming
    COMPANY_ADDRESSES_DESTROY = 'company-address.destroy',
    COMPANY_ADDRESSES_HISTORY = 'company-address.history',
    COMPANY_ADDRESSES_EDIT_POS = 'company-address.edit-pos',
    COMPANY_ADDRESSES_UPDATE_PAYMENTS = 'company-address.payments',

    COMPANY_CATEGORIES_INDEX = 'companyCategory.index',
    COMPANY_CATEGORIES_CREATE = 'companyCategory.create',
    COMPANY_CATEGORIES_EDIT = 'companyCategory.edit',
    COMPANY_CATEGORIES_DESTROY = 'companyCategory.destroy',
    COMPANY_CATEGORIES_HISTORY = 'companyCategory.history',

    COMPANY_REPORTS_INDEX = 'company-report.index',
    COMPANY_REPORTS_CREATE = 'company-report.create',
    COMPANY_REPORTS_EDIT = 'company-report.edit',
    COMPANY_REPORTS_DESTROY = 'company-report.destroy',
    COMPANY_REPORTS_HISTORY = 'company-report.history',

    COURIERS_INDEX = 'courier.index',
    COURIERS_CREATE = 'courier.create',
    COURIERS_EDIT = 'courier.edit',
    COURIERS_DESTROY = 'courier.destroy',
    COURIERS_HISTORY = 'courier.history',

    DELIVERIES_INDEX = 'delivery.index',
    DELIVERIES_CREATE = 'delivery.create',
    DELIVERIES_EDIT = 'delivery.edit',
    DELIVERIES_DESTROY = 'delivery.destroy',
    DELIVERIES_HISTORY = 'delivery.history',

    DELIVERY_TARIFFS_INDEX = 'delivery-tariff.index',
    DELIVERY_TARIFFS_CREATE = 'delivery-tariff.create',
    DELIVERY_TARIFFS_EDIT = 'delivery-tariff.edit',
    DELIVERY_TARIFFS_DESTROY = 'delivery-tariff.destroy',
    DELIVERY_TARIFFS_HISTORY = 'delivery-tariff.history',

    TARIFF_MAPS_INDEX = 'tariff_map.index',
    TARIFF_MAPS_CREATE = 'tariff_map.create',
    TARIFF_MAPS_EDIT = 'tariff_map.edit',
    TARIFF_MAPS_DESTROY = 'tariff_map.destroy',
    TARIFF_MAPS_HISTORY = 'tariff_map.history',

    DISHES_INDEX = 'dish.index',
    DISHES_CREATE = 'dish.create',
    DISHES_EDIT = 'dish.edit',
    DISHES_DESTROY = 'dish.destroy',
    DISHES_HISTORY = 'dish.history',

    GEO_CITIES_INDEX = 'geo-city.index',
    GEO_CITIES_CREATE = 'geo-city.create',
    GEO_CITIES_EDIT = 'geo-city.edit',
    GEO_CITIES_DESTROY = 'geo-city.destroy',
    GEO_CITIES_HISTORY = 'geo-city.history',

    GEO_COUNTRIES_INDEX = 'geo-country.index',
    GEO_COUNTRIES_CREATE = 'geo-country.create',
    GEO_COUNTRIES_EDIT = 'geo-country.edit',
    GEO_COUNTRIES_DESTROY = 'geo-country.destroy',
    GEO_COUNTRIES_HISTORY = 'geo-country.history',

    LANGS_INDEX = 'lang.index',
    LANGS_CREATE = 'lang.create',
    LANGS_EDIT = 'lang.edit',
    LANGS_DESTROY = 'lang.destroy',
    LANGS_HISTORY = 'lang.history',

    MODIFIERS_INDEX = 'modifier.index',
    MODIFIERS_CREATE = 'modifier.create',
    MODIFIERS_EDIT = 'modifier.edit',
    MODIFIERS_DESTROY = 'modifier.destroy',
    MODIFIERS_HISTORY = 'modifier.history',

    MODIFIER_GROUPS_INDEX = 'modifier-group.index',
    MODIFIER_GROUPS_CREATE = 'modifier-group.create',
    MODIFIER_GROUPS_EDIT = 'modifier-group.edit',
    MODIFIER_GROUPS_DESTROY = 'modifier-group.destroy',
    MODIFIER_GROUPS_HISTORY = 'modifier-group.history',

    NOTIFICATIONS_INDEX = 'notification.index',
    NOTIFICATIONS_CREATE = 'notification.create',
    NOTIFICATIONS_EDIT = 'notification.edit',
    NOTIFICATIONS_DESTROY = 'notification.destroy',
    NOTIFICATIONS_HISTORY = 'notification.history',

    NOTIFICATION_CAMPAIGNS_INDEX = 'notification-campaign.index',
    NOTIFICATION_CAMPAIGNS_CREATE = 'notification-campaign.create',
    NOTIFICATION_CAMPAIGNS_EDIT = 'notification-campaign.edit',
    NOTIFICATION_CAMPAIGNS_DESTROY = 'notification-campaign.destroy',
    NOTIFICATION_CAMPAIGNS_HISTORY = 'notification-campaign.history',
    NOTIFICATION_CAMPAIGNS_APPROVE = 'notification-campaign.approve',
    NOTIFICATION_CAMPAIGNS_DECLINE = 'notification-campaign.decline',

    MEDIA_NOTIFICATIONS_INDEX = 'media-notification.index',
    MEDIA_NOTIFICATIONS_CREATE = 'media-notification.create',
    MEDIA_NOTIFICATIONS_EDIT = 'media-notification.edit',
    MEDIA_NOTIFICATIONS_DESTROY = 'media-notification.destroy',
    MEDIA_NOTIFICATIONS_HISTORY = 'media-notification.history',

    ORDERS_INDEX = 'order.index',
    ORDERS_CREATE = 'order.create',
    ORDERS_EDIT = 'order.edit',
    ORDERS_DESTROY = 'order.destroy',
    ORDERS_HISTORY = 'order.history',
    ORDERS_STATISTIC_REPORTS_ACCOUNT = 'order-reports.account',
    ORDERS_STATISTIC_REPORTS_CITY = 'order-reports.city',
    ORDERS_STATISTIC_REPORTS_COMPANY = 'order-reports.company',

    PACKAGES_INDEX = 'packages.index',
    PACKAGES_CREATE = 'packages.create',
    PACKAGES_EDIT = 'packages.edit',
    PACKAGES_DESTROY = 'packages.destroy',
    PACKAGES_HISTORY = 'packages.history',

    PHONE_CALLS_INDEX = 'phone-call.index',
    PHONE_CALLS_CREATE = 'phone-call.create',
    PHONE_CALLS_EDIT = 'phone-call.edit',
    PHONE_CALLS_DESTROY = 'phone-call.destroy',
    PHONE_CALLS_HISTORY = 'phone-call.history',

    PROBLEMS_INDEX = 'problem.index',
    PROBLEMS_CREATE = 'problem.create',
    PROBLEMS_EDIT = 'problem.edit',
    PROBLEMS_DESTROY = 'problem.destroy',
    PROBLEMS_HISTORY = 'problem.history',

    PROMO_CAMPAIGNS_INDEX = 'promo-campaign.index',
    PROMO_CAMPAIGNS_CREATE = 'promo-campaign.create',
    PROMO_CAMPAIGNS_EDIT = 'promo-campaign.edit',
    PROMO_CAMPAIGNS_MANAGE_PROMO_CODES = 'promo-campaign.manage-promo-codes',
    PROMO_CAMPAIGNS_DESTROY = 'promo-campaign.destroy',
    PROMO_CAMPAIGNS_HISTORY = 'promo-campaign.history',

    REFERRAL_BONUSES_INDEX = 'referral-bonus.index',
    REFERRAL_BONUSES_CREATE = 'referral-bonus.create',
    REFERRAL_BONUSES_EDIT = 'referral-bonus.edit',
    REFERRAL_BONUSES_DESTROY = 'referral-bonus.destroy',
    REFERRAL_BONUSES_HISTORY = 'referral-bonus.history',

    STATUSES_INDEX = 'status.index',
    STATUSES_CREATE = 'status.create',
    STATUSES_EDIT = 'status.edit',
    STATUSES_DESTROY = 'status.destroy',
    STATUSES_HISTORY = 'status.history',

    TEMPLATES_INDEX = 'template.index',
    TEMPLATES_CREATE = 'template.create',
    TEMPLATES_EDIT = 'template.edit',
    TEMPLATES_DESTROY = 'template.destroy',
    TEMPLATES_HISTORY = 'template.history',

    USERS_INDEX = 'user.index',
    USERS_CREATE = 'user.create',
    USERS_EDIT = 'user.edit',
    USERS_DESTROY = 'user.destroy',
    USERS_HISTORY = 'user.history',

    ZONES_INDEX = 'zone.index',
    ZONES_CREATE = 'zone.create',
    ZONES_EDIT = 'zone.edit',
    ZONES_DESTROY = 'zone.destroy',
    ZONES_HISTORY = 'zone.history',

CASHBACK_EDIT = 'cashback.edit',
}
