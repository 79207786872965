import {ModalBase} from "@features/modal/base-modal";
import Dropdown, {Option} from "@shared/ui/dropdown";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import {useRouteBuilder} from "@routes/hooks";
import {ROUTES} from "@routes/routes-list";
import {useCompanyAddresses} from "@entities/company/lib/hooks";
import {Toggle} from "@shared/ui/toggle";
import {useAuth} from "@entities/auth/lib/hooks";
import {useUserAgent} from "@shared/lib/hooks";
import classNames from "classnames";
import {COMPANY_STATUS} from "@dto/CompanyDTO";
import {enumToString} from "@shared/lib/helpers";

type ExtendedOption = Option & { addresses: Option[] }
export const CompanyAddressSelector: FC<{
    isOpen: boolean,
    redirect?: string,
    onClose: () => Promise<void>
}> = ({onClose, redirect, isOpen}) => {
    const {companyId, companyAddressId} = useParams()
    const [companyAddress, setCompanyAddress] = useState<ExtendedOption | null>(null)
    const [company, setCompany] = useState<ExtendedOption | null>(null)
    const {buildRoute} = useRouteBuilder()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {data: companies, toOptions} = useCompanyAddresses(isOpen)
    const {isAllCompaniesSelected} = useAuth()
    const [allSelected, setAllSelected] = useState(isAllCompaniesSelected)
    const {permissions: {canSelectAllCompanies}} = useAuth()
    const {isIos} = useUserAgent()
    const goToAddress = async () => {
        if (allSelected) {
            navigate(buildRoute(redirect ?? ROUTES.NOW_ORDERS, {companyId: 'all', companyAddressId: 'all'}))
            onClose()
            return
        }
        if (!company || !companyAddress) return
        navigate(buildRoute(redirect ?? ROUTES.NOW_ORDERS, {
            companyId: company.value,
            companyAddressId: companyAddress.value
        }))
        onClose()
    }

    const companiesOptions: Option[] = useMemo(() => {
        return toOptions()
    }, [companies])

    useEffect(() => {
        if (!companies) return
        if (!company || !companyAddress) {
            const exists = companiesOptions.find(company => company.value === companyId) as ExtendedOption
            setCompany(exists)
            setCompanyAddress(exists?.addresses.find(address => address.value === companyAddressId) as ExtendedOption)
        }
    }, [companiesOptions, company, companyAddress])

    return <ModalBase
        useContentScroll={false}
        canFloatBottom={!isIos}
        header={t('change company address')}
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={'md:max-w-sm'} onConfirm={goToAddress}>
        <div className={"text-sm mb-5"}>
            <label className={"font-medium block pb-2"}>
                {t("company")}
            </label>
            <Dropdown disabled={allSelected} loading={!companies} value={company?.value?.toString()}
                      onSelect={e => {
                          setCompany(e as ExtendedOption);
                          setCompanyAddress((e as ExtendedOption).addresses[0] as ExtendedOption)
                      }}
                      renderOption={(option) => <div
                          title={option.title + ' - ' + t(enumToString(option.status, COMPANY_STATUS, 'company_status'))}
                          className={"flex gap-2 items-center"}><span
                          className={classNames({
                              'status-active': option.status === COMPANY_STATUS.ACTIVE,
                              'status-paused': option.status === COMPANY_STATUS.PAUSED,
                              'status-hidden': option.status === COMPANY_STATUS.HIDDEN,
                              'status-soon': option.status === COMPANY_STATUS.SOON,
                              'status-inactive': option.status === COMPANY_STATUS.INACTIVE,
                          }, 'badge-circle')}/>{option.title}</div>}
                      options={companiesOptions}/>
        </div>

        <div className={"text-sm"}>
            <label className={"font-medium block pb-2"}>
                {t("company address")}
            </label>
            <Dropdown disabled={allSelected} loading={!companies} value={companyAddress?.value?.toString()}
                      onSelect={e => setCompanyAddress(e as ExtendedOption)}
                      options={company?.addresses ?? []}/>
        </div>

        {canSelectAllCompanies && <div className={'mt-4'}>
          <Toggle checked={allSelected} loading={!companyId} onChange={e => setAllSelected(e)}>
              {t('all companies')}
          </Toggle>
        </div>}
    </ModalBase>
}