import {createBrowserRouter, redirect} from "react-router-dom";
import React, {lazy} from "react";
import {GenericError} from "@features/error-pages/generic-error";
import MenuPageLayout from "@pages/catalog/menu-page-layout";
import {OrdersPageLayout} from "@pages/orders/orders-page-layout";
import {LoaderFunctionArgs} from "react-router";
import {StorageManager} from "@features/storage-manager/storage-manager";
import {BASE_PATH, ROUTES} from "@routes/routes-list";
import {formatUrl} from "@shared/lib/helpers";
import {last} from "lodash-es";

const NewOrdersPage = lazy(
  () =>
    import(
      /*webpackChunkName: "orders-new-page"*/ "@pages/orders/new-orders-page"
    ),
);
const NowOrdersPage = lazy(
  () =>
    import(
      /*webpackChunkName: "now-orders-page"*/ "@pages/orders/now-orders-page"
    ),
);
const LaterOrdersPage = lazy(
  () =>
    import(
      /*webpackChunkName: "later-orders-page"*/ "@pages/orders/later-orders-page"
    ),
);
const SettingsPage = lazy(
  () => import(/*webpackChunkName: "settings-page"*/ "@pages/settings-page"),
);
const NotificationsPage = lazy(
  () =>
    import(
      /*webpackChunkName: "notifications-pages"*/ "@pages/notifications-page"
    ),
);
const CallCourierPage = lazy(
  () =>
    import(
      /*webpackChunkName: "call-courier-page"*/ "@pages/call-courier-page"
    ),
);
const ReviewsPage = lazy(
  () => import(/*webpackChunkName: "reviews-page"*/ "@pages/reviews-page"),
);
const ArchivePage = lazy(
  () => import(/*webpackChunkName: "archive-page"*/ "@pages/archive-page"),
);
const StatisticPage = lazy(
  () => import(/*webpackChunkName: "report-page"*/ "@pages/statistic-page"),
);
const MenuCategories = lazy(
  () => import(/*webpackChunkName: "categories"*/ "@pages/catalog/categories"),
);
const MenuModifiers = lazy(
  () => import(/*webpackChunkName: "modifiers"*/ "@pages/catalog/modifiers"),
);

const CashbackPage = lazy(
    () => import(/*webpackChunkName: "cashback"*/ "@pages/cashback.page"),
);


const protectedLoader = (ctx: LoaderFunctionArgs) => {
  const { request } = ctx;
  let isAuthorized = StorageManager.getFromLocal(
    StorageManager.KEYS.AUTH_TOKEN,
  );
  const lang = StorageManager.getFromLocal(StorageManager.KEYS.LAST_LANG, "ua");

  if (!isAuthorized) {
    return redirect(
      formatUrl(ROUTES.LOGIN, { lang, from: new URL(request.url).pathname }),
    );
  }

  let needRedirect = checkLocaleLoader(ctx) || checkCompanyIdLoader(ctx);
  if (needRedirect) return needRedirect;

  return request;
};

const checkCompanyIdLoader = ({ params, request }: LoaderFunctionArgs) => {
  let { companyId, accountId, lang, companyAddressId} = params;
  const { url } = request;
  if (companyId) {
    StorageManager.setToLocal(StorageManager.KEYS.LAST_COMPANY_ID, companyId);
  }
  if(companyAddressId){
    StorageManager.setToLocal(StorageManager.KEYS.LAST_ADDRESS_ID, companyAddressId);
  }
  if (!companyId || !accountId) {
    return redirect("/login");
  }
  const parts = new URL(url).pathname.split("/").filter(Boolean);
  const isMenuPage = last(parts) === "menu";

  const routeParams = { companyId, accountId, lang, companyAddressId}

  if (isMenuPage) {
    return redirect(
      formatUrl(ROUTES.MENU_CATEGORIES, routeParams),
    );
  }
  if (last(parts) === companyId || last(parts) === accountId) {
    return redirect(
      formatUrl(ROUTES.NOW_ORDERS, routeParams),
    );
  }
};

const checkLocaleLoader = (req: LoaderFunctionArgs) => {
  let lang = req.params.lang;
  if (!lang) {
    lang = new URL(req.request.url).pathname.split("/").filter(Boolean)[0];
  }

  StorageManager.setToLocal(StorageManager.KEYS.LAST_LANG, lang);

  if (!["en", "ua", "ru"].includes(lang)) {
    const url = [
      "ua",
      ...new URL(req.request.url).pathname.split("/").filter(Boolean).slice(1),
    ].join("/");
    return redirect(url);
  }
};

export const router = createBrowserRouter([
  {
    path: BASE_PATH,
    errorElement: <GenericError status={500} />,
    hasErrorBoundary: true,
    loader: protectedLoader,
    children: [
      {
        path: BASE_PATH,
        element: <OrdersPageLayout />,
        loader: protectedLoader,
        children: [
          {
            path: ROUTES.NEW_ORDERS,
            index: true,
            Component: NewOrdersPage,
          },
          {
            path: ROUTES.NOW_ORDERS,
            Component: NowOrdersPage,
          },
          {
            path: ROUTES.LATER_ORDERS,
            Component: LaterOrdersPage,
          },
        ],
      },
      {
        path: ROUTES.SETTINGS,
        Component: SettingsPage,
      },
      {
        path: ROUTES.NOTIFICATIONS,
        Component: NotificationsPage,
      },
      {
        path: ROUTES.CALL_COURIER,
        Component: CallCourierPage,
      },
      {
        path: ROUTES.REVIEWS,
        Component: ReviewsPage,
      },
      {
        path: ROUTES.ARCHIVE,
        Component: ArchivePage,
      },
      {
        path: ROUTES.STATISTICS,
        Component: StatisticPage,
      },
      {
        path: ROUTES.MENU,
        element: <MenuPageLayout />,
        loader: protectedLoader,
        children: [
          {
            path: ROUTES.MENU_CATEGORIES,
            index: true,
            Component: MenuCategories,
          },
          {
            path: ROUTES.MENU_MODIFIERS,
            Component: MenuModifiers,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.CASHBACK,
    errorElement: <GenericError status={500} />,
    Component: CashbackPage,
  },
  {
    path: ROUTES.LOGIN,
    Component: lazy(
      () => import(/*webpackChunkName: "LoginPage"*/ "@pages/auth/login"),
    ),
  },
  {
    path: "*",
    loader: (req) => {
      let isAuthorized = StorageManager.getFromLocal(
        StorageManager.KEYS.AUTH_TOKEN,
      );

      const companyId = StorageManager.getFromLocal(
        StorageManager.KEYS.LAST_COMPANY_ID,
      );
      const accountId = StorageManager.getFromLocal(
        StorageManager.KEYS.LAST_ACCOUNT_ID,
      );
      const companyAddressId = StorageManager.getFromLocal(
          StorageManager.KEYS.LAST_ADDRESS_ID,
          'all'
      );
      const lang = StorageManager.getFromLocal(
        StorageManager.KEYS.LAST_LANG,
        "ua",
      );

      if (!isAuthorized) {
        return redirect(formatUrl(ROUTES.LOGIN, { lang }));
      }

      if (companyId && accountId && lang && companyAddressId) {
        return redirect(
          formatUrl(ROUTES.NOW_ORDERS, { companyId, accountId, lang, companyAddressId}),
        );
      }

      return req;
    },
    element: <GenericError status={404} />,
  },
]);
