import {ROUTE_GET_ACCOUNT_SETTINGS} from "@routes/api_routes";
import {fetcher} from "@shared/fetcher";
import {AccountSettingsDTO} from "@dto/AccountSettingsDTO";
import {StorageManager} from "@features/storage-manager/storage-manager";

export const SettingsRepository = {
    getSettings: (accountId: string): Promise<AccountSettingsDTO> => {
        let cached = StorageManager.getFromLocal(ROUTE_GET_ACCOUNT_SETTINGS(accountId))
        if (cached) {
            return Promise.resolve(cached)
        }
        return fetcher<AccountSettingsDTO>(ROUTE_GET_ACCOUNT_SETTINGS(accountId)).then(e => {
            StorageManager.setToLocal(ROUTE_GET_ACCOUNT_SETTINGS(accountId), e, 5)
            return e
        })
    }
}