import {fetcher} from "@shared/fetcher";
import {
    ROUTE_GET_COMPANY,
    ROUTE_GET_COMPANY_ADDRESS,
    ROUTE_GET_COMPANY_ADDRESSES,
    ROUTE_GET_USER_COMPANIES,
    ROUTE_UPDATE_COMPANY_STATUS
} from "@routes/api_routes";
import {WithPagination} from "@dto/WithPagination";
import {AuthCompaniesDTO} from "@dto/AuthResponseDTO";
import {FullCompanyDTO} from "@dto/CompanyDTO";
import {pickBy} from "lodash-es";
import {COMPANY_ADDRESS_STATUS, CompanyAddressDTO} from "@dto/CompanyAddressDTO";
import {StorageManager} from "@features/storage-manager/storage-manager";

export const CompanyRepository = {
    getUserCompanies: (accountId: string, userId: string): Promise<WithPagination<AuthCompaniesDTO>> => {
        const cache = StorageManager.getFromLocal(ROUTE_GET_USER_COMPANIES(accountId, userId))
        if (cache) {
            return Promise.resolve(cache)
        }
        return fetcher<WithPagination<AuthCompaniesDTO>>(ROUTE_GET_USER_COMPANIES(accountId, userId)).then(e => {
            StorageManager.setToLocal(ROUTE_GET_USER_COMPANIES(accountId, userId), e, 5)
            return e
        })
    },
    getCompanyAddresses: (accountId: string, companyId: string): Promise<WithPagination<AuthCompaniesDTO>> => {
        return fetcher(ROUTE_GET_COMPANY_ADDRESSES(accountId, companyId))
    },
    getCompanyAddress: (accountId: string, companyId: string, companyAddressId: string) => {
        return fetcher<CompanyAddressDTO>(ROUTE_GET_COMPANY_ADDRESS({
            accountId: accountId,
            companyId: companyId,
            companyAddressId: companyAddressId
        }))
    },
    getCompany: (accountId: string, companyId: string): Promise<any> => {
        return fetcher<FullCompanyDTO>(ROUTE_GET_COMPANY({accountId: accountId, companyId: companyId}))
    },
    updateCompanyStatus: (accountId: string, companyId: string, companyAddressId: string, data: {
        status: COMPANY_ADDRESS_STATUS,
        scheduledStatusUpdateTime?: number
    }) => {
        return fetcher(ROUTE_UPDATE_COMPANY_STATUS({
            accountId: accountId,
            companyId: companyId,
            companyAddressId: companyAddressId
        }), {
            method: 'PUT', data: pickBy({...data, id: companyAddressId}, x => x !== undefined)
        })
    }
}