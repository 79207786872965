import useSWR from "swr";
import {useParams} from "react-router";
import {useAuth} from "@entities/auth/lib/hooks";
import {useMemo, useState} from "react";
import {OrderRepository, OrdersFilters} from "@src/repositories/OrderRepository";
import {OrderDTO} from "@dto/OrderDTO";
import {isNewOrder, isOrderPreorder, isOrderTestOrDemo} from "@entities/order/lib/resolvers";
import {ORDERS_REFRESH_INTERVAL} from "@src/config";
import {StorageManager} from "@features/storage-manager/storage-manager";
import {getOrdersSchedule, sortOrdersByTime} from "@entities/orders/lib/methods";
import dayjs from "dayjs";
import cpf from "dayjs/plugin/customParseFormat";
import {useCompanyAddress} from "@entities/company/lib/hooks";

dayjs.extend(cpf)

export type GropedOrders = {
    now: OrderDTO[],
    newOrders: OrderDTO[],
    preorder: OrderDTO[]
}
export const useOrdersList = (filters: OrdersFilters = {}) => {
    const {isAuthorized} = useAuth()
    const {companyId, accountId, companyAddressId} = useParams()
    const [showTestOrders] = useState<boolean>(StorageManager.getFromLocal(StorageManager.KEYS.SHOW_TEST_ORDERS, false))
    const {data: address} = useCompanyAddress()
    const {
        mutate,
        ...data
    } = useSWR(
        companyId && isAuthorized && `/no-cache/${accountId}/${companyId}/orders|${JSON.stringify(filters)}`,
        () => OrderRepository.getAllOrders(accountId as string, companyId as string, companyAddressId, filters),
        {
            refreshInterval: ORDERS_REFRESH_INTERVAL,
            dedupingInterval: ORDERS_REFRESH_INTERVAL,
        }
    );

    const fillHoles = (data: Record<string, number>) => {
        let start = dayjs().startOf('h').set('h', 9)
        let end = dayjs().startOf('h').set('h', 22)
        const dow = (new Date().getDay() + 6) % 7

        if (address?.workTime) {
            const today = address.workTime[dow]
            start = dayjs(today.start, 'HH:mm')
            end = dayjs(today.end, 'HH:mm')

        }
        const res = {...data}

        const dif = Math.round(end.diff(start, 'hour', true))

        for (let i = 0; i < dif; i++) {
            const t = start.add(i, 'hour').unix().toString()
            if (!res[t]) res[t] = 0
        }

        return res
    }

    const groupedOrders = useMemo(() => {
        let stub: GropedOrders = {now: [], newOrders: [], preorder: []}
        if (!data.data) return stub

        let unsorted = data.data.items.reduce((acc: GropedOrders, order: OrderDTO) => {
            if (!showTestOrders && isOrderTestOrDemo(order)) return acc
            if (isOrderPreorder(order)) acc.preorder.push(order)
            else if (isNewOrder(order)) acc.newOrders.push(order)
            else acc.now.push(order)
            return acc
        }, stub)

        unsorted.now = sortOrdersByTime(unsorted.now)
        unsorted.preorder = sortOrdersByTime(unsorted.preorder)

        return unsorted

    }, [data.data, showTestOrders])

    const ordersBusySchedule = useMemo(() => {
        let result = getOrdersSchedule(data.data?.items?.filter(x => x.preorder && x.timeMadeIn ? dayjs.unix(x.timeMadeIn).isToday() : true) || []);
        if (!result) return
        if (Object.keys(result).length === 0) return


        return fillHoles(result)
    }, [data.data?.items])

    const onPaginate = async (offset:number) => {
        const orders = await OrderRepository.getAllOrders(accountId as string, companyId as string, companyAddressId, {...filters, offset})

        await mutate((old) => {
            const merged = ([...old?.items ?? [], ...orders.items])
            return ({...old, hasNext: orders.hasNext, items: merged });
        }, {populateCache: true, revalidate: false})
    }

    return {
        ...data,
        groupedOrders,
        ordersBusySchedule,
        onPaginate,
        mutate,
    };
};
export const useOrderAvailableTimes = (orderId?: string) => {
    const {isAuthorized} = useAuth()
    const {companyId, accountId} = useParams()

    return useSWR(orderId && isAuthorized && `/no-cache/order-times/${orderId}`, (x?: string) => !x ? null : OrderRepository.getOrderTime(accountId as string, companyId as string, orderId as string))
}
