import {useNavigate, useParams} from "react-router";
import useSWR from "swr";
import {SettingsRepository} from "@src/repositories/SettingsRepository";
import {useEffect} from "react";
import {useRouteBuilder} from "@routes/hooks";

export const useAccountSettings = () => {
    const {accountId} = useParams()
    const {buildRoute} = useRouteBuilder()
    const navigate = useNavigate()
    const data = useSWR(
        accountId && `/${accountId}/settings`,
        () => SettingsRepository.getSettings(accountId as string),
        {
            dedupingInterval: 6e4
        }
    )

    useEffect(() => {
        if (!data.error) return
        if (data.error.status === 404) {
            navigate(buildRoute('login'))
            localStorage.clear()
        }
    }, [data.error])
    return {
        ...data
    }
}