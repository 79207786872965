import {OrderDTO} from "@dto/OrderDTO";
import dayjs from "dayjs";
import cpf from 'dayjs/plugin/customParseFormat'
import {groupByTime, GroupingInterval} from "@shared/lib/groupByTime";
import {sortBy} from "lodash-es";

dayjs.extend(cpf)


export const getOrdersSchedule = (orders: OrderDTO[], groupingInterval: GroupingInterval = GroupingInterval.HOUR, timeRounding ?: number): Record<number, number> => {
    return groupByTime(orders, 'timeMadeIn', groupingInterval, timeRounding)
}


export const sortOrdersByTime = (orders: OrderDTO[]): OrderDTO[] => {
    type MappedType = { withTime: OrderDTO[], withoutTime: OrderDTO[] }
    if(orders.length === 0) {
        return []
    }
    const {withTime, withoutTime}: MappedType = orders.reduce<MappedType>((acc, order) => {
        if (order.timeMadeIn) {
            acc.withTime.push(order)
        } else {
            acc.withoutTime.push(order)
        }
        return acc
    }, {withTime: [], withoutTime: []})

    if(withoutTime.length === 0) {
        return sortBy(withTime, 'timeMadeIn')
    }

    return [
        ...sortBy(withTime, 'timeMadeIn'),
        ...withoutTime
    ]
}