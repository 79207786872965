import {DELIVERY_TYPE} from "@dto/CompanyDTO";

export enum ORDER_STATUS {
    NEW = 0,
    CONFIRMED = 1,
    DISTRIBUTED = 2,
    ACCEPTED_BY_COMPANY = 3,
    ACCEPTED_BY_DELIVERY = 4,
    DELIVERY_RECEIVED = 5,
    COMPANY_FINISHED = 6,
    FINISHED = 7,
    FAILED = 8,
    DECLINED = 9,
}

export enum CooperationType {
    NONE = 0,
    COOPERATION_ORDER_AND_DELIVERY = 1,
    COOPERATION_ONLY = 2,
}

export const ACTIVE_ORDERS_STATUSES = [
    ORDER_STATUS.DISTRIBUTED,
    ORDER_STATUS.ACCEPTED_BY_COMPANY,
    ORDER_STATUS.ACCEPTED_BY_DELIVERY,
    ORDER_STATUS.DELIVERY_RECEIVED,
    ORDER_STATUS.COMPANY_FINISHED,
]
export const FINISHED_ORDERS_STATUSES = [
    ORDER_STATUS.FINISHED,
    ORDER_STATUS.FAILED,
    ORDER_STATUS.DECLINED,
]

export enum ORDER_DELIVERY_PROVIDER {
    OTHER = 'other',
    DOTS = 'dots',
    GLOVO = 'glovo',
}

export enum PAYMENT_TYPE {
    CASH = 1,
    ONLINE = 2,
    TERMINAL = 3,
}

export enum ORDER_TYPE {
    ORDERED_BY_OPERATOR = 2,
    ORDERED_BY_COMPANY = 3,
    ORDERED_BY_IOS_APP = 4,
    ORDERED_BY_ANDROID_APP = 5,
    ORDERED_BY_API = 1,
}

export interface OrderDTO {
    id: string
    status: ORDER_STATUS
    number: string
    deliveryInfo: {
        deliveryProviderType?: ORDER_DELIVERY_PROVIDER
        deliveryType: DELIVERY_TYPE
        isAsap: boolean
        note?: string
    }
    timeMadeIn: number | null
    createdTime?: number
    user: {
        id: string
        phone: string | null
        name: string | null
    }
    paymentType: PAYMENT_TYPE
    fullPrice: number
    totalCheckPrice: number
    totalCheckBasePrice: number
    preorder: boolean
    testOrDemo: boolean
    companyAddress?: {
        id: string
        address: string
    }
    company: {
        id: string
        name: string
        cooperation: CooperationType
        cityName:string
    }
    note: string | null
    preparationFinished: boolean
    hasExpiredPayments: boolean
}