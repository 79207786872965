import useSWR from "swr";
import {useAuth} from "@entities/auth/lib/hooks";
import {CompanyRepository} from "@src/repositories/CompanyRepository";
import {useParams} from "react-router";
import {uniqBy} from "lodash-es";
import {FullCompanyDTO} from "@dto/CompanyDTO";
import {CompanyAddressDTO} from "@dto/CompanyAddressDTO";

export const useCompanyAddresses = (loadData = true) => {
    const {userId, isAuthorized} = useAuth()
    const {accountId} = useParams()

    const data = useSWR(
        loadData && isAuthorized && `/user/${userId}/companies`,
        () => CompanyRepository.getUserCompanies(accountId as string, userId),
        {
            dedupingInterval: 6e4
        })

    const toOptions = () => {
        if (!data.data) return []
        let res = data.data.items.map(company => ({
            title: `${company.name} (${company.cityName})`,
            value: company.id,
            status: company.status,
            addresses: company.addresses.map(address => ({title: address.address, value: address.id}))
        }))
        res = uniqBy(res, 'value')
        return res
    }

    return {
        ...data,
        toOptions
    }
}

export const useCompany = () => {
    const {userId, isAuthorized, isAllCompaniesSelected} = useAuth()
    const {accountId, companyId} = useParams()

    return useSWR<FullCompanyDTO>(
        isAuthorized && !isAllCompaniesSelected && companyId && `/user/${userId}/companies/${companyId}`,
        () => CompanyRepository.getCompany(accountId as string, companyId as string))

}

export const useCompanyAddress = (load = true) => {
    const {userId, isAuthorized, isAllCompaniesSelected} = useAuth()
    const {accountId, companyId, companyAddressId} = useParams()

    return useSWR<CompanyAddressDTO>(
        isAuthorized && load && companyId && companyAddressId && !isAllCompaniesSelected && `/user/${userId}/companies/${companyId}/${companyAddressId}`,
        () => CompanyRepository.getCompanyAddress(accountId as string, companyId as string, companyAddressId as string))

}