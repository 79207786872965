import qs from 'qs'
import {pickBy} from "lodash-es";

const prepareParams = (params: Record<string, any>) => qs.stringify(pickBy(params, x => x !== undefined && x !== null && x !== ''))

const API_VERSION = process.env.REAPT_APP_API_VERSION || 'v1'
const API_PREFIX = process.env.REACT_APP_API_PREFIX

export type BaseRouteParams = {
    accountId: string,
    companyId: string,
    companyAddressId?: string
}

type BaseRouteWithOrder = (data: BaseRouteParams & { orderId: string }) => string
type BaseRouteWithOrderAndPayment = (data: BaseRouteParams & { orderId: string, paymentId: string }) => string
type BaseRoute = (data: BaseRouteParams) => string
type GetOrdersRoute = (data: BaseRouteParams & { filters: Record<string, any> }) => string
type NotificationsRoute = (data: BaseRouteParams & { notificationId: string }) => string
export const ROUTE_SIGN_IN = `${API_PREFIX}/${API_VERSION}/auth/sign-in` as const

export const ROUTE_GET_COMPANY_ORDERS: GetOrdersRoute = ({accountId, companyId, filters = {}, companyAddressId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders?${prepareParams({
        addressId: companyAddressId,
        companyId,
        ...filters
    })}`;
}

export const ROUTE_GET_ORDER_DETAILS: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}?${prepareParams({companyId})}`
}

export const ROUTE_ACCEPT_ORDER: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/accept?${prepareParams({companyId})}`
}

export const ROUTE_CANCEL_ORDER: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/cancel?${prepareParams({companyId})}`
}

export const ROUTE_ORDER_COMPANY_PREPARATION_FINISH: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/company-preparation-finish?${prepareParams({companyId})}`
}

export const ROUTE_FINISH_ORDER: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/company-finish?${prepareParams({companyId})}`
}


export const ROUTE_GET_ORDER_TIMES: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/made-in-available-times?${prepareParams({companyId})}`
}

export const ROUTE_GET_ORDER_PAYMENTS: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/payments/purchase?${prepareParams({companyId})}`
}

export const ROUTE_UPDATE_ORDER_PRICE: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/price?${prepareParams({companyId})}`
}

export const ROUTE_UPDATE_ORDER_TIME: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/time-made-in?${prepareParams({companyId})}`
}

export const ROUTE_SEND_TO_POS: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/send-to-pos?${prepareParams({companyId})}`
}

export const ROUTE_SEND_PAYMENT_LINK: BaseRouteWithOrder = ({accountId, companyId, orderId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/payments/purchase/send-payment-link?${prepareParams({companyId})}`
}

export const ROUTE_SYNC_PAYMENT: BaseRouteWithOrderAndPayment = ({accountId, companyId, orderId, paymentId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/payments/${paymentId}?${prepareParams({companyId})}`
}

export const ROUTE_GET_COMPANY_ADDRESSES = (accountId: string, companyId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/addresses`
}

export const ROUTE_GET_COMPANY_ADDRESS: BaseRoute = ({accountId, companyId, companyAddressId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/addresses/${companyAddressId}`
}

export const ROUTE_GET_COMPANY: BaseRoute = ({accountId, companyId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}`
}

export const ROUTE_UPDATE_COMPANY_STATUS: BaseRoute = ({accountId, companyId, companyAddressId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/addresses/${companyAddressId}?${prepareParams({addressId: companyAddressId})}`
}


export const ROUTE_GET_USER_COMPANIES = (accountId: string, userId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/users/${userId}/companies `
}

export const ROUTE_GET_NOTIFICATIONS: BaseRoute = ({accountId, companyId, companyAddressId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/notifications?${prepareParams({
        addressId: companyAddressId,
        companyId
    })}`
}

export const ROUTE_HAS_NOTIFICATIONS: BaseRoute = ({accountId, companyId, companyAddressId}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/notifications/has-new?${prepareParams({
        addressId: companyAddressId,
        companyId
    })}`
}

export const ROUTE_ACCEPT_ALL_NOTIFICATIONS: NotificationsRoute = ({
                                                                       accountId,
                                                                       companyId,
                                                                       notificationId,
                                                                       companyAddressId
                                                                   }) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/notifications/accept-all?${prepareParams({
        addressId: companyAddressId,
        companyId,
        notificationId
    })}`
}

export const ROUTE_ACCEPT_NOTIFICATION: NotificationsRoute = ({
                                                                  accountId,
                                                                  companyId,
                                                                  notificationId,
                                                                  companyAddressId
                                                              }) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/addresses/${companyAddressId}/notifications/${notificationId}/accept?${prepareParams({companyId})}`
}

export const ROUTE_GET_COMPANY_CATEGORIES = ({accountId, companyId, companyAddressId, categoryId = ''}: {
    accountId: string,
    companyId: string,
    companyAddressId?: string,
    categoryId?: string
},) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/categories/${categoryId}?${prepareParams({addressId: companyAddressId})}`
}

export const ROUTE_GET_COMPANY_CATEGORIES_ITEMS = ({accountId, companyId, companyAddressId, itemId = ''}: {
    accountId: string,
    companyId: string,
    companyAddressId?: string,
    itemId?: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/items/${itemId}?${prepareParams({addressId: companyAddressId})}`
}

export const ROUTE_GET_COMPANY_MODIFIER_GROUPS = ({accountId, companyId, companyAddressId, modifierId = ''}: {
    accountId: string,
    companyId: string,
    companyAddressId?: string,
    modifierId?: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/modifier-groups/${modifierId}?${prepareParams({addressId: companyAddressId})}`
}

export const ROUTE_GET_MODIFIERS_GROUPS_MODIFIERS = ({accountId, companyId, companyAddressId, modifierId = ''}: {
    accountId: string,
    companyId: string,
    companyAddressId?: string,
    modifierId?: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/modifiers/${modifierId}?${prepareParams({addressId: companyAddressId})}`
}

export const ROUTE_GET_REVIEWS = ({accountId, companyId, companyAddressId, params}: {
    accountId: string,
    companyId?: string,
    companyAddressId?: string,
    params?: Record<string, any>
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/reviews?${qs.stringify(pickBy({
        ...params,
        companyId,
        addressId: companyAddressId
    }, Boolean))}`
}

export const ROUTE_ACCEPT_REVIEW = ({accountId, companyId, reviewId}: {
    accountId: string,
    companyId: string,
    reviewId: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/reviews/${reviewId}/accept?${prepareParams({companyId})}`
}

export const ROUTE_ACCEPT_DECLINE = ({accountId, companyId, reviewId}: {
    accountId: string,
    companyId: string,
    reviewId: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/reviews/${reviewId}/cancel?${prepareParams({companyId})}`
}

export const ROUTE_CREATE_ORDER = ({accountId, companyId, companyAddressId}: {
    accountId: string,
    companyId: string,
    companyAddressId: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders?${prepareParams({
        companyId,
        accountId,
        addressId: companyAddressId
    })}`
}

export const ROUTE_CALCULATE_ORDER_PRICE = ({accountId, companyId, companyAddressId}: {
    accountId: string,
    companyId: string,
    companyAddressId: string
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/calculate-delivery-price?${prepareParams({
        companyId,
        addressId: companyAddressId
    })}`
}

export const ROUTE_GET_ACCOUNT_SETTINGS = (accountId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/settings`
}

export const ROUTE_GET_STATISTICS = ({accountId, companyId, companyAddressId, filters = {}}: {
    accountId: string,
    companyId?: string,
    companyAddressId?: string,
    filters?: Record<string, any>
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/statistics?${prepareParams({
        companyId,
        addressId: companyAddressId, ...filters
    })}`
}

export const ROUTE_EXPORT_ORDERS = ({accountId, companyId, companyAddressId, filters = {}}: {
    accountId: string,
    companyId?: string,
    companyAddressId?: string,
    filters?: Record<string, any>
}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/export-orders?${prepareParams({
        companyId,
        addressId: companyAddressId, ...filters
    })}`
}


export const ROUTE_SEARCH_USER = (accountId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/users/cashback/by-phone`
}
export const ROUTE_USER_CASHBACK_TRANSACTIONS = (accountId: string, userId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/users/${userId}/cashback/transactions`
}


export const ROUTE_POS_LOGS = ({accountId, orderId}: {accountId: string, orderId: string}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/pos/response-logs`
}